// TV2DK HEADER
@import "core";

@include respond-to(base-inline) {
  :root{
    --scrollbar-width: 15px; // used by js
    --localnav-border-height: calc(6 * var(--unit));
  }

  .tc_header{z-index:100;-webkit-user-select:none;user-select:none;
    &:has(.tc_header__local)::after{content:"";position:absolute;left:0;right:0;bottom:0;height:var(--localnav-border-height);background-color:var(--color-bright-sand);}
    &:not([data-variant="reel"]).tc_header--sticky{position:sticky;top:0;will-change:transform;transform:translateY(-100%);
      &.tc_header--sticky-show{transform:translateY(0);}
    }
    &.tc_header--sticky-animate{transition:transform var(--transition-slower);}

    &[data-variant="feature"]{position:absolute;top:0;left:0;right:0;
      &.tc_header--sticky{position:fixed;z-index:1;}
      .tc_header__global{position:relative;z-index:2;}
      &:not(.tc_header--sticky-show) .tc_header__global__buttons{color-scheme:light;}
      & + #main{width:auto;z-index:auto;
        .tc_feature__header{z-index:1;
          & + .tc_grid{position:relative;z-index:0;}
        }
      }
    }
    &[data-variant="reel"]{position:fixed;top:0;left:0;right:0;z-index:2;background-color:transparent;pointer-events:none;
      & + #main{width:auto;z-index:auto;}
      .tc_header__global > {
        .tc_header__global__skip,.tc_header__global__logo,.tc_header__global__buttons{pointer-events:auto;}
      }
      .tc_header__global__buttons > .tc_button{color-scheme:light;}
      .tc_header__global__buttons__close:not([hidden]){
        & + .tc_header__global__buttons__menu{display:none;}
      }
    }
  }

  // Global header
  .tc_header__global{display:flex;align-items:center;gap:var(--spacing-12);height:var(--spacing-72-step);margin:0 var(--gutter);}
  .tc_header__global__skip{clip:rect(var(--spacing-1),var(--spacing-1),var(--spacing-1),var(--spacing-1));position:absolute;top:0;left:0;right:0;margin:0 auto;width:var(--spacing-1);height:var(--spacing-1);overflow:hidden;text-align:center;font-size:var(--font-14);font-weight:var(--font-medium);line-height:var(--line-140);text-decoration:none;
     &:focus{clip:auto;display:block;width:var(--spacing-128);height:auto;z-index:10;}
     &:focus-visible{outline:0;background-color:var(--color-900);color:var(--color-light);}
  }
  .tc_header__global__logo{display:block;min-width:var(--spacing-40-step);max-width:var(--spacing-40-step);border-radius:50%;
    svg{width:100%;height:100%;}
  }
  .tc_header__global__nav{display:flex;list-style:none;gap:var(--spacing-12);flex:1;overflow:hidden;padding:var(--spacing-4);}
  .tc_header__global__nav__item__link{display:flex;align-items:center;height:var(--spacing-40);font-size:var(--font-14-16);font-weight:var(--font-medium);color:light-dark(var(--color-text),var(--color-600));text-decoration:none;
    &[aria-current="true"]{color:light-dark(var(--color-primary-tv2red),var(--color-white));}
  }
  .tc_header__global__buttons{display:flex;flex-direction:row;gap:var(--spacing-16-step);margin-left:auto;margin-bottom:var(--spacing-8);}
  .tc_header__global__buttons__play{display:none;}
  .tc_header__global__buttons__login,.tc_header__global__buttons__menu{padding-right:0;font-size:var(--font-10-10-16);
    .tc_button__text{position:absolute;bottom:calc(var(--spacing-16) * -1);}
  }
  .tc_header__global__buttons__login{
    svg:last-child{display:none;}
    &[data-logged-in="true"]{
      svg:first-child{display:none;}
      svg:last-child{display:block;}
    }
  }

  // Local header
  .tc_header__local{height:var(--spacing-40);
    &::before{content:"";position:absolute;left:0;top:0;z-index:2;width:var(--gutter);height:calc(var(--spacing-40) - var(--localnav-border-height));color:var(--color-light);background:linear-gradient(to right, currentColor 15%, transparent 100%);pointer-events:none;} // currentColor fix for iOS light/dark theme
    &::after{content:"";position:absolute;right:0;top:0;z-index:2;width:var(--spacing-32);height:calc(var(--spacing-40) - var(--localnav-border-height));color:var(--color-light);background:linear-gradient(to left, currentColor 15%, transparent 100%);pointer-events:none;} // currentColor fix for iOS light/dark theme
    .tc_slider__list{height:var(--spacing-40);padding:0 calc(var(--gutter) + var(--spacing-8)) 0 calc(var(--gutter) - var(--spacing-8));scroll-padding:0 calc(var(--gutter) + var(--spacing-8)) 0 calc(var(--gutter) - var(--spacing-8));}
  }
  .tc_header__local__nav__item{display:flex;white-space:nowrap;height:var(--spacing-40);
    .tc_header__local__nav__item__link[aria-current="true"]{color:var(--color-text);
      &::after{content:"";position:absolute;z-index:1;left:0;right:0;bottom:0;height:calc(var(--spacing-4) + var(--spacing-2));background-color:var(--color-primary-tv2red);}
    }
  }
  .tc_header__local__nav__item__link{position:relative;z-index:1;display:flex;align-items:center;padding:0 var(--spacing-8);font-size:var(--font-14);font-weight:var(--font-medium);color:var(--color-600);text-decoration:none;transition:color var(--transition-fast);
    &:focus-visible{outline-offset:calc(var(--spacing-2) * -1);}
  }

  // Theming
  .tc_header[data-site="nyheder"]{
    &:has(.tc_header__local)::after{background-color:var(--color-bright-peach);}
  }
  .tc_header[data-site="sport"]{
    &:has(.tc_header__local)::after{background-color:var(--color-bright-sky);}
    .tc_header__global__nav__item__link[aria-current="true"]{color:light-dark(var(--color-primary-blue),var(--color-white));}
    .tc_header__local__nav__item__link[aria-current="true"]::after{background-color:var(--color-primary-blue);}
  }
  .tc_header[data-site="vejr"]{
    &:has(.tc_header__local)::after{background-color:var(--color-light-green);}
    .tc_header__global__nav__item__link[aria-current="true"]{color:light-dark(var(--color-primary-green),var(--color-white));}
    .tc_header__local__nav__item__link[aria-current="true"]::after{background-color:var(--color-primary-green);}
  }
  .tc_header[data-site="echo"]{
    &:has(.tc_header__local)::after{background-color:var(--color-bright-lavendar);}
    .tc_header__global__nav__item__link[aria-current="true"]{color:light-dark(var(--color-primary-violet),var(--color-white));}
    .tc_header__local__nav__item__link[aria-current="true"]::after{background-color:var(--color-primary-violet);}
  }

  // Light theme fallback (iOS < 17.5)
  @supports not (color: light-dark(#fff, #000)) {
    .tc_header__global__nav__item__link{color:var(--color-text);
      &[aria-current="true"]{color:var(--color-primary-tv2red);}
    }
    .tc_header[data-site="sport"] .tc_header__global__nav__item__link[aria-current="true"]{color:var(--color-primary-blue);}
    .tc_header[data-site="vejr"] .tc_header__global__nav__item__link[aria-current="true"]{color:var(--color-primary-green);}
    .tc_header[data-site="echo"] .tc_header__global__nav__item__link[aria-current="true"]{color:var(--color-primary-violet);}
  }

  // Menu dialog
  .tc_header__global__buttons__dialog{position:fixed;z-index:10;top:0;right:0;margin:0 0 0 auto;width:calc(460 * var(--unit));max-width:100%;height:100vh;max-height:100vh;transform:translateX(100%);transition:transform var(--transition-slower);border:0;padding:0;background-color:var(--color-100);will-change:transform;-webkit-user-select:none;user-select:none;overflow:hidden;filter:blur(0.000001px); // filter fixes Chrome bug with blurred text on translated element
    &:focus-visible{outline:none;}
    &::backdrop{background-color:var(--color-backdrop);opacity:0;transition:opacity var(--transition-slower);}
    .tc_header__global__buttons__menu[aria-expanded="true"] + &{transform:translateX(0);
      &::backdrop{opacity:1;}
    }
  }

  html:has(.tc_header__global__buttons__menu[aria-expanded="true"]){margin-right:var(--scrollbar-width);}
  body:has(.tc_header__global__buttons__menu[aria-expanded="true"]){overflow:hidden;}

  // no js fallback
  .tc_header__global__buttons__dialog:popover-open{transform:translateX(0);
    &::backdrop{opacity:1;}
  }
  html:has(.tc_header__global__buttons__dialog:popover-open){margin-right:var(--scrollbar-width);}
  body:has(.tc_header__global__buttons__dialog:popover-open){overflow:hidden;}

  @supports (height:1dvh){
    .tc_header__global__buttons__dialog{height:100dvh;max-height:100dvh;}
  }
}

@include respond-to(small-inline) {
  // Global header
  .tc_header__global__nav{padding-left:var(--spacing-16);gap:var(--spacing-24);}

  // Menu dialog
  .tc_header__global__buttons__dialog{background:transparent linear-gradient(to left, var(--color-100), var(--color-100) calc(384 * var(--unit)),transparent calc(384 * var(--unit)));}
}

@include respond-to(medium-inline) {
  .tc_header[data-variant="reel"]{
    .tc_header__global__buttons > .tc_button{
      &.tc_button--default:not(:hover),&.tc_button--icon:not(:hover) .tc_button__icon{background:var(--color-light-200);color:var(--color-light-900);}
    }
  }

  // Global header
  .tc_header__global__nav{padding-left:var(--spacing-32);gap:var(--spacing-16);}
  .tc_header__global__buttons{margin-bottom:0;}
  .tc_header__global__buttons__play{display:inline-flex;}

  // Local header
  .tc_header__local .tc_slider__list{padding:0 calc(var(--gutter) - var(--spacing-8));}
}

@include respond-to(large-inline) {
  // Global header
  .tc_header__global{gap:var(--spacing-16);}
  .tc_header__global,.tc_header__local{margin:0 clamp(var(--gutter), -120px + 12vw, var(--spacing-96));} // scale spacing
  .tc_header__global__nav{padding-left:var(--spacing-40);gap:var(--spacing-32);}
  .tc_header__global__buttons__login,.tc_header__global__buttons__menu{padding-right:var(--spacing-12);
    .tc_button__text{position:static;}
  }

  // Local header
  .tc_header__local .tc_slider__list{padding:0 0 0 var(--spacing-96);scroll-padding:0 0 0 var(--spacing-96);}
}

@include respond-to(hover) {
  .tc_header__global__logo:hover circle{transition:all var(--transition-normal);fill:var(--color-interactive-tv2red);}
  .tc_header__global__nav__item__link:hover{text-decoration:underline;}
  .tc_header__local__nav__item__link:hover{color:var(--color-text);}
}

@include respond-to(print) {
  .tc_header__global__skip,.tc_header__global__nav,.tc_header__global__buttons,.tc_header__local,.tc_header__global__buttons__dialog{display:none;}
  .tc_header__global{height:var(--spacing-80);}
  .tc_header__global__logo{display:block;width:var(--spacing-48);height:var(--spacing-48);
    svg{width:100%;height:100%;}
  }
}
